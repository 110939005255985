export const ETHlogo = ({
  color,
  size,
}: {
  color?: string | undefined;
  size: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`-16 -5 36 36`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.944 17.97L4.58 13.62 11.943 24l7.37-10.38-7.372 4.35h.003zM12.056 0L4.69 12.223l7.365 4.354 7.365-4.35L12.056 0z"
      fill={color}
    />
  </svg>
);
